@import "./newamericadotorg/assets/scss/_mixins.scss"; $static: "/static"; $extra-static: "https://d3fvh0lm0eshry.cloudfront.net/static";
.mobile-menu .menu-list > * {
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  @include box-shadow( inset 0px 1px 0px 0px color(black, base, 0.1) !important );

  &:last-child{
    box-shadow: inset 0px 1px 0px 0px color(black, base, 0.1),
    inset 0px -1px 0px 0px color(black, base, 0.1) !important;
  }

  a, button {
    padding-top: 27px;
    padding-bottom: 27px;
  }

  &.tab-link button{
    font-weight: unset;
    font-size: unset;
    text-transform: unset;
    letter-spacing: unset;
    width: 100%;
    text-align: left;
  }

  &.tab-link a:after, &.tab-link button:after{
    content: '';
    height: 10px;
    width: 10px;
    border-top: 1px solid color(black, base, 0.5);
    border-right: 1px solid color(black, base, 0.5);
    position: absolute;
    right: 15px;
    margin-bottom: 5px;
    top: 50%;
    @include transform(translateY(-50%) rotate(45deg) translateZ(0));
  }
}
