.card.variable .card__image{
  width: 100%;
  height: auto;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
  }

  .card__image__background {
    height: 100%;
  }
}

.card.variable .card__text{
  //height: 135px;
}
