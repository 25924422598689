.in-depth__home-page {
	&__header {
		height: 314px;
		padding: 50px 0px;
		background-color: color(black);
		text-align: center;
		background-repeat: no-repeat;
		background-position: center;

		&__text {
			max-width: 750px;
			padding-left: 25px;
			padding-right: 25px;
			margin: auto;
			position: relative;
			top: 50%;
			transform: translateY(-50%);

			.post-header__text__heading {
				color: white;
				padding-bottom: 5px;
			}
			.post-header__text__subheading {
				color: white;
				line-height: 130%;
				font-size: 17px;

				@include media-breakpoint(tablet) {
					font-size: 20px;
				}
			}

		}
	}

	&__content {
		max-width: $max-site-width;
		margin: auto;

		@include media-breakpoint(tablet) {
			padding-left: 25px;
			padding-right: 25px;
		}

		.content-grid__controls {
			margin-bottom: 25px;

			@include media-breakpoint(tablet) {
				margin-top: 35px;
				margin-left: 0px;
			}

		}
	}
}
