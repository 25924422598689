@font-face {
    font-family: 'Circular';
    src: url('#{$extra-static}/fonts/CircularStd-Black.otf');
    src: url('#{$extra-static}/fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
         url('#{$extra-static}/fonts/CircularStd-Black.woff2') format('woff2'),
         url('#{$extra-static}/fonts/CircularStd-Black.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Circular';
    src: url('#{$extra-static}/fonts/CircularStd-Book.ttf');
    src: url('#{$extra-static}/fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
         url('#{$extra-static}/fonts/CircularStd-Book.woff2') format('woff2'),
         url('#{$extra-static}/fonts/CircularStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Platform';
  src: url('#{$extra-static}/fonts/Platform-Medium.otf');
  src: url('#{$extra-static}/fonts/Platform-Medium.eot?#iefix') format('embedded-opentype'),
       url('#{$extra-static}/fonts/Platform-Medium.woff2') format('woff2'),
       url('#{$extra-static}/fonts/Platform-Medium.woff') format('woff');
  font-weight: bold;
}

@font-face {
    font-family: 'Lyon';
    src: url('#{$extra-static}/fonts/LyonText-Regular.otf');
    src: url('#{$extra-static}/fonts/LyonText-Regular.eot?#iefix') format('embedded-opentype'),
         url('#{$extra-static}/fonts/LyonText-Regular.woff2') format('woff2'),
         url('#{$extra-static}/fonts/LyonText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: none;
}

@font-face {
    font-family: 'Lyon';
    src: url('#{$extra-static}/fonts/LyonText-Bold.otf');
    src: url('#{$extra-static}/fonts/LyonText-Bold.eot?#iefix') format('embedded-opentype'),
         url('#{$extra-static}/fonts/LyonText-Bold.woff2') format('woff2'),
         url('#{$extra-static}/fonts/LyonText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: none;
}


@font-face {
    font-family: 'Lyon';
    src: url('#{$extra-static}/fonts/LyonText-RegularItalic.otf');
    src: url('#{$extra-static}/fonts/LyonText-RegularItalic.eot?#iefix') format('embedded-opentype'),
         url('#{$extra-static}/fonts/LyonText-RegularItalic.woff2') format('woff2'),
         url('#{$extra-static}/fonts/LyonText-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lyon';
    src: url('#{$extra-static}/fonts/LyonText-BoldItalic.otf');
    src: url('#{$extra-static}/fonts/LyonText-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('#{$extra-static}/fonts/LyonText-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}
