.logo{
  display: inline-block;
  background-size: 95% auto;
  background-position: 0 center;
  background-repeat: no-repeat;
  background-image: url('#{$extra-static}/logo-black.svg');

  &.lg {
    width: 200px;
    height: 50px;
  }

  &.md{
    width: 134px;
    height: 34px;
  }

  &.sm{
    width: 100px;
    height: 24px;
    //background-image: url('#{$extra-static}/logo-black-mini.svg');
  }

  &.white{
    background-image: url('#{$extra-static}/logo-white.svg');
  }

  &.bug{
    background-image: url('#{$extra-static}/logo-bug.svg');
    &.white {
      background-image: url('#{$extra-static}/logo-bug-white.svg');
    }

    &.white-transparent {
        background-image: url('#{$extra-static}/logo-white-transparent.svg');
    }
  }
}
