button {
	border: none;
	background: transparent;
	padding: 0;
	margin: 0;
}

.button, .tag{
	text-align: center;
	white-space: nowrap;
	display: inline-block;
	border-radius: 2px;
	position: relative;
	background: transparent;
	@include transition(all $transition-function);
	cursor: pointer;
}

.button {
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 25px;
	padding-right: 25px;
}

.button.with-caret--left:before {
	content: ' ';
	position: relative;
	top: type-adjusted-padding(1px);
	display: inline-block;
	vertical-align: middle;
	margin-right: 15px;
	width: 5px;
	height: 5px;
	border-right: 1px solid color(black);
	border-bottom: 1px solid color(black);
	@include transform(rotate(135deg));
	left: -5px;
}

.button.with-caret--right:after {
	content: ' ';
	position: relative;
	top: type-adjusted-padding(1px);
	display: inline-block;
	vertical-align: middle;
	margin-right: 15px;
	width: 5px;
	height: 5px;
	border-right: 1px solid color(black);
	border-bottom: 1px solid color(black);
	@include transform(rotate(-45deg));
	right: -15px;
}

.button:hover{
	&:after, &:before{
		border-right: 1px solid color(white);
		border-bottom: 1px solid color(white);
	}
}

.tag{
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
	@include media-breakpoint(desktop){
		padding-top: 15px;
		padding-bottom: 15px;
		padding-left: 25px;
		padding-right: 25px;
	}
}

.tag {
	margin-right: 6px;
  margin-bottom: 6px;
}

.button {
	border: 1px solid color(black, base, 1);
	&:hover {
		background: color(black, base, 1);
		border: 1px solid color(black, base, 0);
		color: color(white);
	}

	&.block {
		display: block;
	}

	&.white {
		border: 1px solid color(white, base, 1);
		background: transparent;
		color: color(white);
		&:hover {
			border: 1px solid color(white, base, 0);
			background: color(white);
			color: color(black);
		}
	}

	&.white-outline{
		border: 1px solid color(white, base, 1);
		color: color(white);
		&:hover {
			background: color(white);
			color: color(black);
		}
	}

	&.black {
		border: 1px solid color(black, base, 0);
		background: color(black);
		color: color(white) !important;
		&:hover {
			border: 1px solid color(black, base, 0);
			background: color(black, base, 0.75);
		}
	}

	&.turquoise {
		border: 1px solid color(turquoise, dark, 1);
		color: color(turquoise, dark);
		background: color(turquoise, dark, 0);
		&:hover{
			color: color(white) !important;
			background: color(turquoise, dark, 1);
		}
	}
}

.tag {
	background-color: color(black, base, 0.1);

	&:hover{
		background-color: color(black, base, 0.3);
	}
}
