.icon-arrow {
  height: 8px;
  width: 14px;
  position: relative;

  & > * {
    position: absolute;
    height: 1px;
    background: color(black, base, 1);
  }

  &.white > * {
    background: color(white, base, 1);
  }

  & > :first-child{
    @include vertical-center;
    width: 100%;
  }

  & > :nth-child(2){
    top: 2px;
    @include transform(rotate(45deg));
    right: 0;
    width: 4px;
  }

  & > :nth-child(3){
    bottom: 2px;
    width: 4px;
    right: 0;
    @include transform(rotate(-45deg));
  }

  &.left {
    & > :nth-child(2){
      left: 0;
      @include transform(rotate(-45deg));
    }
    & > :nth-child(3){
      left: 0;
      @include transform(rotate(45deg));
    }
  }

}
