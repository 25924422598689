@include media-breakpoint(tablet){
  .margin-md-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .margin-top-md-0{
    margin-top: 0px !important;
  }

  .margin-bottom-md-0{
    margin-bottom: 0px !important;
  }

  .margin-md-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .margin-bottom-md-5 {
    margin-bottom: 5px !important;
  }

  .margin-top-md-5 {
    margin-top: 5px !important;
  }

  .margin-md-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .margin-top-md-10{
    margin-top: 10px !important;
  }

  .margin-bottom-md-10{
    margin-bottom: 10px !important;
  }

  .margin-md-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .margin-bottom-md-15 {
    margin-bottom: 15px !important;
  }

  .margin-top-md-15 {
    margin-top: 15px !important;
  }

  .margin-md-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .margin-bottom-md-25 {
    margin-bottom: 25px !important;
  }

  .margin-top-md-25 {
    margin-top: 25px !important;
  }

  .margin-md-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .margin-bottom-md-35 {
    margin-bottom: 35px !important;
  }

  .margin-top-md-35 {
    margin-top: 35px !important;
  }

  .margin-md-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .margin-top-md-60 {
    margin-top: 60px !important;
  }

  .margin-bottom-md-60 {
    margin-bottom: 60px !important;
  }

  .margin-md-80{
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .margin-top-md-80 {
    margin-top: 80px !important;
  }

  .margin-bottom-md-80{
    margin-bottom: 80px !important;
  }

}

@include media-breakpoint(desktop){
  .margin-lg-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .margin-top-lg-0{
    margin-top: 0px !important;
  }

  .margin-bottom-lg-0{
    margin-bottom: 0px !important;
  }

  .margin-lg-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .margin-bottom-lg-5 {
    margin-bottom: 5px !important;
  }

  .margin-top-lg-5 {
    margin-top: 5px !important;
  }

  .margin-lg-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .margin-top-lg-10{
    margin-top: 10px !important;
  }

  .margin-bottom-lg-10{
    margin-bottom: 10px !important;
  }

  .margin-lg-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .margin-bottom-lg-15 {
    margin-bottom: 15px !important;
  }

  .margin-top-lg-15 {
    margin-top: 15px !important;
  }

  .margin-lg-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .margin-bottom-lg-25 {
    margin-bottom: 25px !important;
  }

  .margin-top-lg-25 {
    margin-top: 25px !important;
  }

  .margin-lg-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .margin-bottom-lg-35 {
    margin-bottom: 35px !important;
  }

  .margin-top-lg-35 {
    margin-top: 35px !important;
  }

  .margin-lg-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .margin-top-lg-60 {
    margin-top: 60px !important;
  }

  .margin-bottom-lg-60 {
    margin-bottom: 60px !important;
  }

  .margin-lg-80{
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .margin-top-lg-80 {
    margin-top: 80px !important;
  }

  .margin-bottom-lg-80{
    margin-bottom: 80px !important;
  }
}
