.card.person{
  height: 160px;
  padding: 0;
  
  & > a {
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .card__text{
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    flex-grow: 1;
  }

  .card__image{
    width: 120px;
    height: 120px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      -webkit-filter: grayscale(100%);
      filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
      filter: grayscale(100%);
      @include transition(filter 0.15s ease);
    }

    &.no-image{
      background-color: color(black, base, 0.15);
      position: relative;
      &:after{
        position: absolute;
        @include center;
        font-weight: normal;
        font-family: 'FontAwesome';
        color: color(black, base, 0.075);
        font-size: 3rem;
        content: '\f007';
      }
    }
  }

  &:hover{
    img {
      -webkit-filter: grayscale(0%);
      filter: none;
      filter: grayscale(0%);
    }
  }
}
