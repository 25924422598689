.in-depth__panel__body > *, .in-depth__panel .block-iframe__max-bounds-container {
    margin-left: auto;
    margin-right: auto;
}

.post-body .block-iframe__responsive-container {
    position: relative;
    height: 0px;
    overflow: hidden;
}

.post-body .block-iframe__responsive-container {
    position: relative;
    height: 0px;
    overflow: hidden;
}

.post-body .block-iframe__responsive-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.post-body .block-iframe__responsive-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.dataviz__icon{
  display: none !important;
}
