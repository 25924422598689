.card {
  background: color(white);
  box-shadow: inset 0px -4px 0px 0px color(turquoise, base, 0),
    0px 2px 6px 0px color(black, base, 0);
  @include transform(translateZ(0));
  @include transition(box-shadow 0.15s ease, top 0.15s ease);
  top: 0;
  position: relative;
  margin-top: 10px;
  cursor: default;

  & > a {
    width: 100%;
    height: 100%;
  }

  &__image{
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: color(black, base, 0.05);

    &__background{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.75;
      -webkit-filter: blur(20px);
      filter: blur(20px);
    }

    img{
      width: auto;
      height: 100%;
    }

    &.no-image {
      background-color: color(black, base, 0.15);
      &:after {
        content: '\f1ea';
        @include center;
        font-size: 3rem;
        font-weight: normal;
        position: absolute;
        color: color(black, base, 0.075);
        font-family: 'fontawesome';

      }

      img {
        display: none;
      }
    }
  }

  &__text{
    padding: 25px;
    position: relative;
    //border-bottom: 1.5px solid color(turquoise);
    &:after{
      //content: '';
      bottom: 0px;
      position: absolute;
      width: 105px;
      border: 1.5px solid color(turquoise, dark);
    }
  }

  &:not(.highlights):not(.person__card) {
    position: relative;
    top: 0;

    .card__text__title {
      @include transition(color 0.15s ease);
    }

    &:hover{
      .card__text__title {
      //  color: color(black, base, 0.6);
      }

      @include box-shadow( 0px 2px 6px 0px color(black, base, 0.15) );
      top: -3px;
    }
  }
}

.card {
  &.md, &.sm, &.lg, &.list, &.person, &.variable {
    img {
      opacity: 0;
      @include transition(transform 0.15s ease, opacity 0.15s ease);
      &.loaded {
        opacity: 1;
      }
    }
  }
}

:not(h2).card__text__title:hover u{
  display: inline;
  background-image: linear-gradient(90deg, color(turquoise) 100%, transparent 0);
  background-position: 0 1.04em;
  background-repeat: repeat-x;
  background-size: 100% 2px;
}

h2.card__text__title:hover u{
  text-decoration: underline;
  text-decoration-color: color(turquoise);
  -webkit-text-decoration-color: color(turquoise);
}

@import 'event';
@import 'highlight';
@import 'list';
@import 'lg';
@import 'md';
@import 'sm';
@import 'variable';
@import 'person';
@import 'promo';
@import 'program';
@import 'accent';
