@import "./newamericadotorg/assets/scss/_mixins.scss"; $static: "/static"; $extra-static: "https://d3fvh0lm0eshry.cloudfront.net/static";
.mobile-menu__heading{
  top: 0;
  left: 0;
  position: absolute;
  background: color(white);
  height: $header-height;
  white-space: nowrap;
  z-index: 1;

  .logo {
    width: 120px !important;
    height: 40px !important;
  }
}

.mobile-menu .logo__wrapper {
  position: relative;
  display: inline-block;
  padding-left: 15px;
  padding-top: 15px;
  @include transform(translateX(0));
  width: 100vw;
  vertical-align: top;
  opacity: 1;
  @include transition(transform 0.4s ease-in-out, opacity 0.4s ease-in-out);
}

.mobile-menu .tab-link-back {
  height: 12px;
  vertical-align: top;
  display: inline-block;
  position: absolute;
  top: 32.5px;
  text-align: center;
  @include transform(translateX(-100vw) translateZ(0));
  width: 100vw;
  opacity: 0;
  padding-left: 0px !important;
  @include transition(transform 0.4s ease-in-out, opacity 0.4s ease-in-out);

  &:before{
    position: absolute !important;
    top: 0;
    left: 15px !important;
    width: 10px !important;
    height: 10px !important;
    border-left: 1px solid color(black, base, 0.5) !important;
    border-bottom: 1px solid color(black, base, 0.5) !important;
  }
}

.mobile-menu.secondary-tab-active {
  .logo__wrapper{
    @include transform(translateX(-100vw) translateZ(0));
    opacity: 0;
  }
  .tab-link-back {
    @include transform(translateX(0) translateZ(0));
    opacity: 1;
  }
}
