.icon-plus {
  width: 10px;
  height: 10px;
  position: relative;
  display: inline-block;
  @include transition(transform 0.35s ease);
  @include transform(rotate(0deg));

  &.lg{
    width: 20px;
    height: 20px;
  }

  & > * {
    height: 1px;
    position: absolute;
    display: block;
    background: color(black);
    width: 100%;
  }

  &.white > * {
    background: color(white);
  }

  & > *:first-child {
    @include vertical-center;
  }

  & > *:nth-child(2){
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%) rotate(90deg));
  }

  &.x {
    @include transform(rotate(45deg));
  }

}
