.header__nav__dropdown__list__item{
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 15px;
  border-bottom: 1px solid $link-border-color;

  li {
    display: block;
  }
}

.programs-dropdown, .about-dropdown{
  text-align: center;
}

.programs-dropdown-wrapper.container{
  text-align: left;
  padding-left: 15px !important;
  padding-right: 15px !important;
  // max-width: 1200px;
  margin: 0 auto;
}

.about-dropdown-wrapper.container{
  text-align: left;
  margin: 0 auto;
  padding-left: 15px !important;
  padding-right: 15px !important;
  // max-width: 1200px;

  .about-text, .about-image{
    display: inline-block;
    vertical-align: middle;
  }

  .about-text{
    width: 350px;
  }

  .about-image{
    width: 210px;
    img {
      width: 170px;
      height: auto;
    }
  }
}
