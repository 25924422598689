.contents-panel__item__description{
}

.contents-panel{
  padding-top: 100px;
  padding-bottom: 100px;

  .contents-panel__item{
    max-width: 400px;
  }

  .contents-panel__item:last-child{
    margin-bottom: 0 !important;
    max-width: 400px;
  }
}
