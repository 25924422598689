.icon-doc{
  width: 20px;
  height: 20px;
  display: inline-block;
  @include box-shadow( 0px 0px 0px 2px color(black) );

  & > div {
    width: 10px;
    margin-left: 5px;
    height: 2px;
    background: color(black);
    margin-bottom: 1px;
  }

  & > div:first-child {
    margin-top: calc(25% - 1px);
  }

  & > div:last-child {
    width: 42%;
    margin-bottom: 0px;
    display: none;
  }
}
