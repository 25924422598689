.program__heading__wrapper{
  background-color: color(white);
  padding-top: 85px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  position: relative;
  h1 {
    line-height: type-adjusted-padding(1em);
  }
}

.program__heading__logo {
  max-height: 1em;
  max-width: 100%;
}
