.timeline {
	padding: 45px 0px 25px 0px;
	margin: 25px 0px;
	border: 2px solid palette-get(na-black-10);
	position: relative;
	max-width: $max-site-width;
	cursor: auto;

	&.loading {
		cursor: wait !important;
	}

	&__content {
		$transition: height 100ms linear;
		position: relative;

		-webkit-transition: $transition;
	    -moz-transition: $transition;
	    -o-transition: $transition;
	    transition: $transition;

	    margin: 0px 25px;

	}

	&__visible-event-window {
		max-width: $max-body-width;
		width: calc(100vw - 125px);
		overflow: hidden;
		margin: auto;
		position: relative;


		@include media-breakpoint(tablet) {
			width: calc(100% - #{2*$desktop-next-prev-width});

			.list-view & {
				width: 100%;
			}
		}

	}

	&__full-event-container {
		$transition: transform 500ms ease-in-out;
		width: 10000%;
		position: relative;
		-webkit-transition: $transition;
	    -moz-transition: $transition;
	    -o-transition: $transition;
	    transition: $transition;

	    .list-view & {
			transform: translateX(0) !important;
		}
	}

	&__navigation-prompt {
		position: relative;
		margin: auto;
		top: 0px;
		right: 0px;
		text-align: center;
		@include timeline-subtext;
		font-size: 10px;
		padding-bottom: 3px;

		&__content:after {
			content: "Use Arrow Keys to Explore";
			.touch & {
				content: "Swipe to Explore";
			}
		}

		.list-view & {
			display: none;
		}
	}
}