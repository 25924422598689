.card--accent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 15px;
  background: color(white);
  border-left: 10px solid color(turquoise);
  cursor: pointer;
  .card__title {
    font-weight: 800;
    font-family: $label-font-family;
    transition: color 0.15s ease;
    margin: 0;
    &:hover u {
      text-decoration: underline;
      text-decoration-color: color(turquoise, dark);
      display: inline;
      background-image: linear-gradient(90deg, color(turquoise, dark) 100%, transparent 0);
      background-position: 0 1.04em;
      background-repeat: repeat-x;
      background-size: 100% 2px;
    }
  }
  .card__description {
    margin: 20px 0 0 0;
    p {
      font-family: $label-font-family;
      font-size: 15px;
      font-weight: 400;
      line-height: 19px;
      display: block;
      margin: 0;
    }
  }
}
