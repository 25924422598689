.person-heading{
  padding-bottom: 25px;

  @include media-breakpoint(tablet){
    padding-bottom: 75px;
  }

  &__image{
    margin-bottom: 25px;
    img {
      display: block;
      margin: 0 auto;
      width: 175px;
      height: 175px;
      border-radius: 175px;
    }
  }
}

.person-heading__image__link-wrapper img{
  width: auto;
  display: block;
  height: 125px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint(desktop){
    max-width: 100%;
    height: auto;
    margin:0;
    position: static;
  }
}
