#na-post .post-heading{
  margin: 0 auto;
}

.post-heading__image__wrapper{
  position: relative;
  overflow: hidden;
}

.post-heading__image img {
  width: 100%;
  height: auto;
  @include transition(opacity 0.4s linear);
  opacity: 0;
  position: absolute;
  top: 0px;
  &.loaded {
    opacity: 1;
  }
}

.post-heading__image label {
  position: relative;
  z-index: 1;
}

.post-heading__image .temp-image{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.75;
  -webkit-filter: blur(20px);
  filter: blur(20px);
}


.policypaperpage .post-heading__text,
.bookpage .post-heading__text{
  text-align: center;
}
