@-ms-viewport {
  width: device-width;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

html, body{
  color: color(black);
  background: color(white);
  font-family: $label-font-family;
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

body.scroll-disabled {
  position: fixed;
  overflow: hidden;
}

body.scroll-md-disabled {
  position: fixed;
  overflow: hidden;
  @include media-breakpoint(desktop){
    position: static;
    overflow: auto;
  }
}

body.bg-beige, .bg-beige{
  background-color: color(beige);
}

ul, ol {
  &.no-list-style, &.inline {
    margin: 0;
  	padding: 0;
    list-style-type: none;
    li {
    	padding: 0;
    }
  }

  &.inline li {
    display: inline-block;
    padding-right: 25px;
  }
}

main {
  min-height: 100vh;
}

span {
  &.block {
    display: block;
  }
}

figure {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

iframe{
  border: 0;
}

hr {
  border: none;
	border-bottom: 1px solid color(black, base, 1);
}

section {
  &.beige {
    background-color: color(beige);
  }
}

.skip-link {
  position: fixed;
  right: 105%;
  top: 0.5em;
  background-color: color(white);
  padding: 0.5em;
  &:hover, &:active, &:focus {
    left: 0.5em;
    right: auto;
    box-shadow: 0 0 0 2px color(red);
    z-index: 1000;
  }
}