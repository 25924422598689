.homepage__promo-link{
  overflow: hidden;
  padding-bottom: 5px;
}

.homepage__promo-link a{
  position: relative;
  span {
    position: relative;
    display: inline-block;
    &:after{
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      content: '';
      display: block;
      @include transform(translateX(-100%));
      @include box-shadow( 0px 1px 0px 0px color(turquoise) );
      @include transition(transform 0.2s ease);
    }
  }

  &:hover{
    span:after {
      @include transform(translateX(0%));
    }
  }
}
