.horizontal-nav{
  background-color: color(white);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;

  ul.inline li {
    padding-right: 35px;
  }
}
