.header-dropdown-links__image{
  overflow: hidden;
  h6{
    max-width: 200px;
  }

  .about-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    //filter: grayscale(100%);
    width: 200px;
    height: 75px;
    padding-top: 8px;
    padding-bottom: 9px;
  }

  .subtitle{
    padding-top: 15px;
    padding-bottom: 10px;
    span {
      position: relative;
      display: inline-block;
      &:after{
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1.5px;
        content: '';
        display: block;
        @include transform(translateX(-100%));
        @include box-shadow( 0px 1px 0px 0px color(turquoise) );
        @include transition(transform 0.3s ease);
      }
    }
  }

  &:hover{
    .subtitle span:after {
      @include transform(translateX(0%));
    }
  }
}
