.header__nav{
  display: none;
  @include media-breakpoint(desktop){
    display: block;
  }
  position: static;
  @extend %clearfix;

  & > * {
    float: right;
  }

  &__links{
    @extend %clearfix;
  }

  &__mobile-menu-icon {
    text-align: center;
  }
}
