h1,h2,h3,h4,h5,h6{
  &.link {
    > a, > span{
      display: inline-block;
      position: relative;
      overflow: hidden;
      top: 1px;
      padding-bottom: 1px;
      vertical-align: bottom;
      u {
        position: relative;
        z-index: 1;
      }

      &:before {
        content: '';
        display: block;
        left: 0;
        position: absolute;
        z-index: 0;
        bottom: 0px;
        width: 100%;
        height: 2px;
        background: color(turquoise);
        @include transition(transform 0.15s ease);
        @include transform(translateX(-105%));
      }

      &:hover:before,
      &:active:before,
      &.active:before{
        @include transform(translateX(0%));
      }
    }

    &:hover, &.active, &:active {
      > a:before, > span:before{
        @include transform(translateX(0%));
      }
    }
  }
}

h1.card__text__title.link,
h2.card__text__title.link,
h3.card__text__title.link,
h4.card__text__title.link{
  &:hover u{
    text-decoration: underline;
    -webkit-text-decoration-color: color(turquoise);
    text-decoration-color: color(turquoise);
  }

  > a:before, > span:before{
    display: none;
  }
}

.header__nav__dropdown.active .link span:before{
  @include transform(translateX(0%));
}
