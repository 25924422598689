.timeline__next-prev {
	&-container {
		width: 100%;
		display: table;
		margin: auto;

		@include media-breakpoint(tablet) {
			display: block;
			position: absolute;
			top: 0px;
		}

		.list-view & {
			display: none;
		}
	}
	&__date {
		color: $sub-color;
		font-size: .8rem;
		margin-bottom: 0px;
	}
	&__title {
		color: $sub-color;
		font-size: .8rem;
		font-weight: bold;
		margin-bottom: 0px;

		&.italicize {
			font-style: italic;
		}
	}
}

.timeline__next, .timeline__prev {
	display: table-cell;
	width: 50%;
	cursor: pointer;

	@include media-breakpoint(tablet) {
		display: block;
		position: absolute;
		width: $desktop-next-prev-width;
	}

	&.hidden {
		display: none;
	}
	&:hover {
		* {
			fill: color(black);
			color: color(black);
		}
	}
}

.timeline__next {
	padding-left: 20px;
	text-align: right;
	@include media-breakpoint(tablet) {
		right: 0px;
		text-align: left;
	}

	svg {
		width: 20px;
		fill: $sub-color;
		transform: rotate(90deg);
		/*left: 17px;
		top: -28px;*/
	}
}

.timeline__prev {
	padding-right: 20px;

	text-align: left;
	@include media-breakpoint(tablet) {
		left: 0px;
		text-align: right;
	}

	svg {
		width: 20px;
		fill: $sub-color;
		transform: rotate(-90deg);
		/*right: 15px;
		top: -28px;*/
	}
}