@include media-breakpoint(tablet){
  .post-aside-wrapper{
    max-width: 328px;
  }

  .report__body__aside{
    &:first-child .post-aside-wrapper{
      margin: 0 0 0 auto;
    }
    &:nth-child(2) .post-aside-wrapper{
      margin: 0 auto 0 0;
    }
  }
}


aside.post__social{
  font-size: 2.5rem;
  padding-top: $type-adjustment;
}
