.block-box{
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 40px;
  background-color: #f5f5f5;

  &__title {
    text-transform: uppercase;
    letter-spacing: .02em;
    margin-top: 0;
  }

  p, li, blockquote, a{
    font-family: $label-font-family !important;
    font-size: 1rem !important;
    line-height: 1.75rem !important;
  }

  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  blockquote {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  > .block-paragraph:first-child .rich-text {
    h1, h2, h3, h4, h5, h6, p {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
