$header-font-family: 'Platform';
$label-font-family: 'Circular';
$paragraph-font-family: 'Lyon';
$header-height: 70px;
$header-expanded-height: 165px;
$header-transition-function: 0.3s ease;
$subheader-height: 50px;
$transition-function: 0.15s ease-in-out;
$border: 1px solid color(black, base, 0.3);
$max-site-width: 1200px;
$max-body-width: 700px;
$type-adjustment: 0.16129032258em;
$media-breakpoints: (
  mobile: 576px,
  tablet: 768px,
  desktop: 992px,
  large-desktop: 1200px
);

$colors: (
  turquoise: (
    base: #2ebcb3,
    light: #46ccc3,
    dark: #007E76
  ),
  blue: (
    base: #10355F
  ),
  purple: (
    base: #a076ac
  ),
  beige: (
    base: #F7F4F2
  ),
  oti-blue: (
    base: #5da4da,
    light: #75bef4,
    dark: #3B75A2
  ),
  red: (
    base: #e75c64,
    light: #e75c64,
    dark: #692025
  ),
  black: (
    base: #333333,
    light: #5f6268,
    70: rgba(44,47,53,0.7),
    40: #6b6d71,
    30: #c0c1c3,
    10: #e0e0e1
  ),
  gray: (
    base: #D5D5D5,
    dark: #6F6F74,
    light: #C1C1C1,
    ultra-light: #f6f6f6
  ),
  white: (
    base: #ffffff,
    light: #ffffff,
    dark: #ffffff
  )
);

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@function color($color, $tone: 'base', $opacity: 1) {
    @return rgba(map-get(map-get($colors, $color), $tone), $opacity);
}

@mixin breakpoint($px){
  @media (min-width: $px) { @content; }
}

@mixin breakpoint-down($px){
  @media (max-width: $px) { @content; }
}

@mixin media-breakpoint($point){
  @media (min-width: map-get($media-breakpoints, $point)) { @content; }
}

@mixin media-breakpoint-down($point){
  @media (max-width: map-get($media-breakpoints, $point)) { @content; }
}

@mixin transform($args){
  -webkit-transform: $args;
  -moz-transform: $args;
  -o-transform: $args;
  transform: $args;
}

@mixin transition($args...){
  -webkit-transition: $args;
  -moz-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transition-delay($seconds){
  -webkit-transition-delay: $seconds;
  transition-delay: $seconds;
}

@mixin vertical-center {
  top: 50%;
  @include transform(translateY(-50%));
}

@mixin horizontal-center {
  left: 50%;
  @include transform(translateX(-50%));
}

@mixin box-shadow($shadow...){
  -moz-box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin center {
  left: 50%;
  top: 50%;
  @include transform(translate(-50%,-50%));
}

@mixin flex-order($order){
  -webkit-box-ordinal-group: $order;
  -moz-box-ordinal-group: $order;
  -ms-flex-order: $order;
  -webkit-order: $order;
  order: $order;
}

// padding minus descender height, which for Platform bold is ~16% of line-height
@function type-adjusted-padding($padding){
  @return calc(#{$padding} - #{$type-adjustment});
}


//bootstrap
@mixin linear-gradient-horizontal($start-color, $end-color, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Opera 12
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
}

@mixin linear-gradient-vertical($start-color, $end-color, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Opera 12
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
}

@mixin animation($animations) {
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -o-animation-delay: $delay;
  animation-delay: $delay;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}
