.conference__section{
  .block-video{
    width: 100%;
    max-width: none;
    margin-bottom: 160px;
    iframe {
      width: 100%;
      max-height: 600px;
      height: 100vh;
    }
  }
}

.about__image {
  float: left;
  max-height: 600px;
  width: 33.3333%;
  margin-left: -16.6667;
  margin-right: 35px;
  margin-bottom: 35px;

  img {
    width: 100%;
    height: auto;
  }
}
