.header__logo{
  position: absolute;
  @include vertical-center;
  line-height: $header-height;
  // width: 94px;
  // height: 24px;
  width: 120px;
  height: 40px;
  @include transition(background-image 0.15s ease);
  &:after{
    //preload white logo
    content: '';
    background: url(#{$extra-static}/logo-white.svg) no-repeat -9999px -9999px;
  }
  &:after{
    //preload white logo
    content: '';
    background: url(#{$extra-static}/logo-black.svg) no-repeat -9999px -9999px;
  }
}

.header__logo-wrapper{
  height: $header-height;
  position: relative;
  a {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }

  &:before{
    display: none;
  }
}
