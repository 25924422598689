@import "./newamericadotorg/assets/scss/_mixins.scss"; $static: "/static"; $extra-static: "https://d3fvh0lm0eshry.cloudfront.net/static";
.mobile-menu .input input {
  display: inline-block;
  width: calc(100% - 75px);
  vertical-align: middle;
  border: 0;
  margin-top: 16px;
}

.mobile-menu .input form {
  padding-bottom: 8px;
}

.mobile-menu .input .button--text{
  display: inline-block;
  vertical-align: middle;
  margin-top: 10px;
}

.mobile-menu .input .search-icon{
  margin-top: 15px;
  margin-right: 15px;
  vertical-align: middle;
}
