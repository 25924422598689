[data-toggle^="collapse"] {
  .collapse{
    position: relative;
  //  height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: .35s;
    transition-property: height;

  }
}
