.panel {
  padding: 60px 20px;
  margin-top: 25px;

  @include media-breakpoint(tablet) {
    margin-left: -1000px;
    margin-right:-1000px;

    .has-sidemenu > & {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  h1,
  p {
    text-align: center;
    max-width: 600px;
    margin: auto;
  }
  h1 {
    font-weight: bold;
    color: color(black);
    font-size: 1.75rem;
    line-height: 100%;
    @include media-breakpoint(tablet) {
      font-size: 2rem;
    }
  }
  p {
    font-family: $paragraph-font-family;
    opacity: 0.7;
    margin: 20px auto 0px;
    font-size: rem-calc(20);
  }
  &__link {
    margin-top: 25px;
    text-align: center;
  }
}
