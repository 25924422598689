.aside{
  box-shadow: inset 0px -1px 0px 0px color(black, base, 0.3),
  inset 0px 1px 0px 0px color(black, base, 0.3);
  padding-top: 25px;
  padding-bottom: 25px;

  & > *:last-child{
    margin-bottom: 0px !important;

    & > *:last-child{
      margin-bottom: 0px !important;
    }
  }

  & > *:first-child {
    margin-top: 0px !important;

    & > *:first-child{
      margin-top: 0px !important;
    }
  }
}
