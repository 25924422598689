.post-body .richtext-image, .rich-text img {
  margin-bottom: 15px;
  margin-top: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: auto;

  &:not(.right):not(.left):last-child{
    margin-bottom: 0px;
  }

  &:not(.right):not(.left):first-child{
    margin-top: 0px;
  }

  &.right{
    float: right;
    margin-left: 15px;
    margin-right: 0px;
    max-width: calc(50% - 15px);
  }

  &.left {
    float: left;
    max-width: calc(50% - 15px);
    margin-right: 15px;
    margin-left: 0px;
  }

  @include media-breakpoint(tablet){
    &.right, &.left{
      max-width: 50%;
    }
  }
}
