.header__nav__search {
  white-space: nowrap;
  padding-left: 1.2em;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;

  & > div {
    display: inline-block;
  }
}

.header__nav__search__icon{
  padding-top: 30px;
  vertical-align: top;
}

.header__nav__search__input{
  @include transition(width 0.3s ease);
  overflow: hidden;
  width: 0px;
  padding-top: 3px;
  display: inline-block;
  vertical-align: top;
  height: $header-height;
}

body.search-is-open .header__nav__search__input{
  width: 250px;
}

.header__nav__search__form {
  display: flex;
}

.header__nav__search__submit {
  padding-right: 0.5rem;
  margin-right: 0.5rem;
  width: auto !important;
  border-width: 0 !important;
  border-bottom: 1px solid color(black, base, 0.3) !important;
  &:hover, &:active, &:focus {
    color: color(turquoise, dark);
  }
}
