$section-header-height-medium: 100px;
$section-header-height: 80px;

#na-react__in-depth-section-nav, .in-depth__title-block__text{
  display: inline-block;
	vertical-align: middle;
}

.in-depth__header__left, .in-depth__header__right{
		&:before{
			content: '';
			display: inline-block;
			height: $section-header-height;
			vertical-align: middle;
		}
}

#na-react__in-depth-section-nav{
	width: 100%;

  @include media-breakpoint(tablet){
    padding-left: 50px;
    padding-right: 50px;
  }
  .in-depth-section-nav{
    width: 100%;
  }
  .slick-arrow{
    position: absolute;
    cursor: pointer;
    @include vertical-center;
    &.slick-prev{
      left: -65px;
    }
    &.slick-next{
      right: -65px;
    }
  }
  .slick-list{
    width: 100%;
  }
	.slick-slide {
		height: $section-header-height;
		h6 {
			margin: 0;
			width: 100%;
      padding: 10px;
			position: relative;
	    top: 50%;
	    @include transform(translateY(-50%));
		}
	}

	.slick-disabled{
		visibility: hidden;
	}
}

.in-depth__header {

	background-color: color(black);
	color: white;
	padding: 25px;
	z-index: 15;
	position: relative;
  padding: 0 !important;

	&__right {
		height: $section-header-height;
		overflow: hidden;
		position: relative;
	}

 	&__item-container {
 		height: $section-header-height;
 		width: 100%;
 		padding: 0px 35px;
 		margin: auto;
 	}

 	&__item {
 		height: $section-header-height;
 		&__text {
 			margin-bottom: 0px;
 			padding: 10px 10px;
 			position: relative;
 			top: 50%;
 			transform: translateY(-50%);
        	text-align: center;

 			a {
 				color: color(black);
	 			font-weight: bold;
	 			font-size: 1.1rem;

	 			.active & {
	 				color: white;
	 			}
 			}
 		}
 	}

 	&__arrow__previous, &__arrow__next {
 		height: 20px;
 		width: 20px;
 		position: absolute;
 		cursor: pointer;
 		z-index: 10;
 		svg {
 			fill: white;
 		}
 	}

 	&__arrow__previous {
 		top: 50%;
 		transform: translateY(-50%);
 	}

 	&__arrow__next {
 		right: 0;
 		top: 50%;
 		transform: translateY(-50%);
 	}
}
