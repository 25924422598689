@use "sass:math";
.checkbox {
  $root: &;
  $indicator-size: 12px;
  $indicator-size--checkbox: 15px;

  position: relative;
  display: block;
  cursor: pointer;
  line-height: 18px;
  padding-left: 22px;
  margin-top: 15px;
  margin-bottom: 15px;

  &__label {
    display: block;
  }

  &__input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: -10px;
    top: -10px;
    opacity: 0;
    z-index: 5;
  }

  &__indicator {
    @include transition(all 0.15s linear);
    position: absolute;
    top: 0.5 * (18px - $indicator-size);
    left: 0;
    width: $indicator-size;
    height: $indicator-size;
    background: color(black, base, 0.3);

    #{$root}--checkbox & {
      top: 0.5 * (18px - $indicator-size--checkbox);
      left: 0;
      width: $indicator-size--checkbox;
      height: $indicator-size--checkbox;
      background: none;
      box-shadow: inset 0 0 0 2px color(black, base);
    }

    #{$root}--radio-button & {
      border-radius: 50%;
    }

    #{$root}__input:hover ~ & {
      background: color(black, base, 0.6);
      #{$root}--checkbox & {
        background: color(black, base, 0.3);
      }
    }

    #{$root}__input:focus ~ & {
      box-shadow: inset 0 0 0 2px #0e647d;
    }

    &::before {
      @include transition(all 0.15s linear);
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: color(black, base, 1);
      transform: scale(0);
      
      #{$root}--radio-button & {
        border-radius: 50%;
      }
      
      // States

      #{$root}__input:checked ~ & {
        transform: scale(1);
      }

      #{$root}--checkbox #{$root}__input:checked:not(:focus):hover ~ & {
        background: #666;
      }

      #{$root}__input:checked:focus ~ & {
        background: #0e647d;
      }
    }
    
    &::after {
      @include transition(all 0.15s linear);
      position: absolute;
      $width: math.div(2, 3) * $indicator-size--checkbox;
      $height: math.div(1, 3) * $indicator-size--checkbox;
      height: $height;
      width: $width;
      top: 0.5 * ($indicator-size--checkbox - (1.4 * $height));
      left: 0.5 * ($indicator-size--checkbox - $width);
      box-sizing: border-box;
      border: 0px solid color(white, base);
      border-bottom-width: 2px;
      border-left-width: 2px;
      transform: scale(0) rotate(-45deg);
      
      #{$root}--checkbox & {
        content: '';
      }

      // States

      #{$root}__input:checked ~ & {
        transform: scale(1) rotate(-45deg);
      }
    }
  }
}
