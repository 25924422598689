.margin-0{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.margin-top-0{
  margin-top: 0px !important;
}

.margin-bottom-0{
  margin-bottom: 0px !important;
}

.margin-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.margin-top-10{
  margin-top: 10px !important;
}

.margin-bottom-10{
  margin-bottom: 10px !important;
}

.margin-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.margin-bottom-35 {
  margin-bottom: 35px !important;
}

.margin-top-35 {
  margin-top: 35px !important;
}

.margin-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.margin-80{
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.margin-top-80 {
  margin-top: 80px !important;
}

.margin-bottom-80{
  margin-bottom: 80px !important;
}

.margin-90{
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.margin-top-90 {
  margin-top: 90px !important;
}

.margin-bottom-90{
  margin-bottom: 90px !important;
}
