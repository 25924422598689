.card.promo {
  .promo__heading{
    width: 170px;
    background: color(black);
    @include media-breakpoint(desktop){
      width: 170px;
    }
    h5 {
      text-align: center;
      padding-top: 25px;
      padding-bottom: 25px;
      @include media-breakpoint(desktop){
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%) rotate(-90deg));
      }
    }
  }
  .card__text{
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    @include media-breakpoint(desktop){
      padding-left: 80px;
      padding-right: 80px;
      padding-top: 80px;
      padding-bottom: 80px;
      width: calc(100% - 170px);
    }
  }

  .promo__subscribe{
    max-width: 500px;
  }
}

.card.promo-md {
  min-height: 400px;
  .card__text{
    padding-bottom: 50px;
  }
  h2{
    line-height: 1.9rem;
  }

  .card__link-to{
    width: 100%;
    position: absolute;
    text-align: right;
    right: 25px;
    bottom: type-adjusted-padding(25px);
  }
}
