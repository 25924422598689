.conference__intro{
  height: 100vh;
  position: relative;
  margin-top:#{-$header-height};
}

.conference__title{
  padding-top: $header-height;
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  @include center;

  .na-logo img{
    max-width: calc(100vw - 30px);
    display: block;
    left: 50%;
    @include transform(translateX(-50%));
    position: absolute;
    top: 0;

    @media (min-width: 430px){
      max-width: 400px;
    }
  }
}

.conference__title__location-date {
  text-align: center;
  > h4 {
    display: inline-block;
    margin: 10px;
  }
}

.conference__title__image {
  display: block;
  max-width: 60%;
  max-height: calc(100vh - 500px);
  margin: 30px auto;
}