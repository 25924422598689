.card.lg {

  .col-lg-8 {
    @include media-breakpoint(tablet){
      padding-right: 3px;
    }
  }

  @include media-breakpoint(tablet){
    height: 375px;
  }

  @include media-breakpoint(large-desktop){
    height: 430px;
  }
  .card__text{
    height: 100%;
    padding-bottom: 25px;
    @include media-breakpoint(tablet){
      padding-bottom: 0px;
    }
  }

  .card__image{
    position: relative;
    height: 375px;

    @include media-breakpoint(large-desktop){
      height: 430px;
    }
  }

  img {
    width: auto;
    height: 105%;
    position: absolute;
    @include center;
    @include media-breakpoint(desktop){
      width: 125%;
      height: auto;
    }
  }
}
