.block-heading {
  margin-top: 50px;
}

h2 + .report__body__section__article .block-heading:first-child h3,
h2 + .report__body__section__article .block-heading:first-child,
h2 + .report__body__section__article .block-paragraph:first-child .rich-text:first-child h3:first-child{
  margin-top: 25px !important;
}

@mixin report__h2 {
  font-size: 1.875rem;
  max-width: 90%;
  @include media-breakpoint(desktop){
    max-width: 80%;
  }
}

.post_body, .report__body{
  .block-paragraph, .block-heading {
    h2 {
      @include report__h2;
    }

    h3 {
      margin-top: 50px;
      max-width: 90%;
      @include media-breakpoint(desktop){
        max-width: 80%;
      }
    }

    h4 {
      margin-top: 25px;
      font-size: 19px;
      line-height: 1.3em;
      max-width: 80%;
    }

    h5 {
      margin-top: 25px;
      font-size: 0.75rem;
      letter-spacing: 0.046875rem;
      max-width: 75%;
      text-transform: uppercase;
      max-width: 90%;
      @include media-breakpoint(desktop){
        max-width: 75%;
      }
    }
  }
}

.report__body__section-title, .figure.width-full + .block-paragraph h2 {
  margin-bottom: 35px;
  // @include report__h2;
}