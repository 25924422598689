.block-video {
  margin-bottom: 25px;
  iframe {
    width: 100%;
    height: 375px;
  }
}

.post-body iframe{
  max-width: 100%;
}
