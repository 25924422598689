.card.sm {

  @include media-breakpoint(tablet){
    height: 185px;
  }
  .card__text{
    width: 100%;
    padding-bottom: 50px;
    @include media-breakpoint(tablet){
      height: 100%;
      width: auto;
    }
  }

  .card__image{
    position: relative;
    padding-bottom: 100%;
    width: 100%;
    @include media-breakpoint(tablet){
      padding-bottom: 0;
      width: auto;
      height: 185px;
    }
  }

  img {
    position: absolute;
    @include center;
    width: auto;
    height: 100%;
  }
}
