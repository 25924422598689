.in-depth__section {
   width: 100%;

   &__mobile-selector {
      z-index: 15;
      width: 100%;
      font-size: 1rem;
      position: relative;
      height: 40px;
      margin-bottom: 15px;

      &:after{
        content: '';
        border-right: 1px solid color(white);
        border-bottom: 1px solid color(white);
        top: 50%;
        @include transform(translateY(-50%) rotate(45deg));
        display: inline-block;
        width: 5px;
        height: 5px;
        position: absolute;
        margin-top: -2px;
        right: 15px;
      }

      select {
        width: 100%;
        height: 100%;
        background: transparent;
        position: absolute;
        border-radius: 2px;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        @include vertical-center;
         border: 1px solid color(white);
         background-size: 12px 12px;
         padding-right: 30px;
         padding-left: 15px;
         background-position-y: center;
         background-position-x: 97%;
         margin-bottom: 0px;
         color: white;
         padding-top: 0px;
         padding-bottom: 0px;
      }

   }
}
