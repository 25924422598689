$subtext-size: .9rem;
$sub-color: #b1b1b4;
$desktop-next-prev-width: 120px;
@mixin timeline-subtext {
	color: $sub-color;
	font-size: $subtext-size;
}

@import 'main';
@import 'event';
@import 'nav';
@import 'next-prev';
@import 'title-block';
@import 'see-all-button';
@import 'category-legend';
@import 'split-button';