.homepage .story-grid{
  .mobile-only{
    @include media-breakpoint(desktop){
      display: none;
    }
  }

  .desktop-only{
    display: none;
    @include media-breakpoint(desktop){
      display: block;
    }
  }
}
