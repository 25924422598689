.personpage{
  background: color(beige);
}

.person {
  padding-bottom: 115px;
}

.person__card{
  padding: 35px 10px;
  @include media-breakpoint(tablet){
    padding: 60px 35px;
  }
  @include media-breakpoint(desktop){
    padding: 80px;
    margin-bottom: 10px;
  }
}

.person__card .post-body{
  max-width: 700px;
  margin: 0 auto;
}
