.conference__header__sticky-wrapper{
  background: color(black);
  position: relative;
  height: $header-height;
  @include box-shadow( inset 0px -1px 0px 0px color(black, base, 0.1) );

  h6{
    line-height: 65px;
    padding-right: 15px;
  }

  .button{
    line-height: 40px;
    border-radius: 0 !important;
    border: 0;
    background: #40ad8e;
    &:hover{
      background: color(white);
      color: color(black) !important;
      border: 0;
    }
  }
}

.conference__header{
  height: $header-height;
  .navigation-link.active{
    font-weight: bold;
    @include box-shadow( 0px 2px 0px 0px color(white) );
  }

  &.scroll-entered .conference__header__sticky-wrapper{
    position: fixed;
    top: 0;
    z-index: 200;
    width: 100%;
  }

  .col-0{
    display: none;
    @include media-breakpoint(tablet){
      display: block;
    }
  }

  .navigation-item{
    padding-right: 10px;
    @include media-breakpoint(tablet){
      padding-right: 25px;
    }
  }

}


.conference__nav{
  white-space: nowrap;
}
