.na-dataviz,
.wrapper--streamfield-block {
  position: relative;
  z-index: 1;

  &.full-width{
    width: 100vw;
    margin-left: calc(-50vw + 50%);
  }

  &.width-1200 {
    width: calc(100vw - 30px);
    max-width: 1200px;
    margin-left: calc(-50vw + 50% + 15px);

    @media (min-width: 1230px) {
      margin-left: -250px;

      .report & {
        margin-left: -275px;
      }
    }
  }
}
