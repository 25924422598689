.blog-lead-story {
	width: 100%;
	margin-top: 25px;
	height: 225px;
	display: none;

	.hero-grid__element__text-bar {
		max-height: none;
		max-width: 950px;
		* {
			color: white;
			margin-bottom: 10px;
		}
	}

	@include media-breakpoint(tablet) {
		display: block;
		height: 450px;
		margin-bottom: 40px;
	}
}
