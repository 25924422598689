.card.list {
  padding: 15px;
  height: auto;
  white-space: nowrap;

  @include media-breakpoint(tablet){
    height: 210px;

    &.card--search-small, &.card--search-large {
      height: auto;
    }
  }

  .card__text__excerpt {
    display: none;
    @include media-breakpoint(tablet){
      display: block;
    }
  }

  .card__text__authors > *{
    display: none !important;
    @include media-breakpoint(tablet){
      display: inline-block !important;
    }
  }
  .card__text__authors > *:first-child,
  .card__text__authors > *:nth-child(2){
    display: inline-block !important;
  }

  .card__image {
    white-space: normal;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    height: 75px;
    width: 90px;

    @include media-breakpoint(tablet){
      display: inline-block;
      width: 232px;
      height: 180px;
    }

    img {
      height: 125%;
      width: auto;
      position: absolute;
      @include center;
    }
  }

  &.card--book img {
    height: 100%;
  }

  .card__text{
    white-space: normal;
    vertical-align: middle;
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    height: 100%;
    max-width: calc(100% - 90px);

    &:first-child {
      padding: 0;
    }

    @include media-breakpoint(tablet){
      max-width: calc(100% - 257px);
      height: auto;
    }
  }

  &.card--search-small, &.card--search-large {
    display: flex;
    align-items: center;

    .card__image-link {
      width: 33%;
      height: auto;
      margin-right: 10px;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .card__image {
      width: 100%;
      padding-bottom: 83.33%;
      height: 0;
    }

    .card__text {
      max-width: none;
      height: auto;
    }
  } 
}
